import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAccount } from 'wagmi';
import getChainName from '../../helpers/getChainName';
import { getCollectionPath } from '../../helpers/path';
import { getCdnUrl } from '../../lib/cdnUrl';
import { ChainIcon } from '../Icon';
import { ImageFixedAO } from '../Image_fixed_aspect_ratio';

const Explore_collection_item = ({ itemFor, collectionData, ...props }) => {
  const { address: walletAddress } = useAccount();
  const { sortedCollectionData } = useSelector((state) => state.counter);

  const [itemData, setItemData] = useState([]);
  useEffect(() => {
    if (itemFor === 'userPage') {
      setItemData(collectionData);
    } else {
      setItemData(collectionData);
    }
  }, [sortedCollectionData, itemFor]);

  return (
    <>
      {collectionData?.map((item) => {
        const {
          address,
          coverPhoto,
          profilePhoto,
          headerPhoto,
          name,
          profilePhotoPath,
          pathName,
          itemCount,
          userName,
          coverPhotoPath,
          creatorAddress,
          chain,
        } = item;
        return (
          <article key={address}>
            <div className='rounded-2xl border border-jacarta-100 bg-white transition-shadow hover:shadow-lg  dark:border-jacarta-700 dark:bg-jacarta-700'>
              <Link href={getCollectionPath(chain, address)}>
                <a className='flex space-x-[0.625rem]'>
                  <span className='w-[100%]'>
                    <ImageFixedAO image={getCdnUrl(profilePhotoPath)} resolution={262} alt={name} />
                  </span>
                </a>
              </Link>

              <div className={'w-full p-[1.1875rem]'}>
                <div className='flex w-full items-center justify-between space-x-1'>
                  <Link href={getCollectionPath(chain, address)}>
                    <a className='contents font-display text-base text-jacarta-700 hover:text-accent dark:text-white dark:hover:text-accent'>
                      {name}
                    </a>
                  </Link>
                  <ChainIcon name={chain} tooltip={getChainName(chain)} width={18} type={'chain'} />
                </div>

                <div className='mt-2 flex items-center justify-between text-sm font-medium tracking-tight'>
                  <span className='text-sm dark:text-jacarta-300'>{itemCount} Items</span>
                  <div className='flex flex-wrap items-center'>
                    {creatorAddress === walletAddress && (
                      <Link href={'/collection/edit/' + address}>
                        <a className='text-accent' target='_blank'>
                          <span>Edit</span>
                        </a>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </article>
        );
      })}
    </>
  );
};

export default Explore_collection_item;
